import {
   appleAuthType,
   changePasswordType,
   checkCodeType,
   createCodeType,
   createPasswordType,
   createUserType,
   getTokenType,
   getUserType,
   googleAuthType,
} from "./types";
import { authApiAxios } from "./index";
import { createRequest } from "../../core/LTools/core/apiMethods/createRequest";
import { store } from "../../store";
import { dataToQueryString } from "../../core/LTools/core/apiMethods/dataToQueryString";

const baseUrl = "/user";

class AuthApiClass {
   user_id: string | null = null;

   constructor() {}

   getUser = async (props: getUserType) => {
      return await createRequest(
         authApiAxios.get(baseUrl + dataToQueryString(props))
      );
   };

   createUser = async (data: createUserType) => {
      return await createRequest(authApiAxios.post(baseUrl, data));
   };

   deleteUser = async () => {
     return await createRequest(authApiAxios.delete(`${baseUrl}/${this.user_id}`))
   };

   getToken = async (props: getTokenType) => {
      return createRequest(
         authApiAxios.post(baseUrl + `/${this.user_id}/token`, {
            password: props.password,
         })
      );
   };
   createCode = async ({ template }: createCodeType) => {
      return createRequest(
         authApiAxios.post(`${baseUrl}/${this.user_id}/code`, {
            template,
         })
      );
   };
   checkCode = async (props: checkCodeType) => {
      return createRequest(
         authApiAxios.head(`${baseUrl}/${this.user_id}/code/${props.code}`)
      );
   };
   googleAuth = async (props: googleAuthType) => {
      return createRequest(
         authApiAxios.post(`/user/googleauth`, {
            google_token: props.google_token,
         })
      );
   };
   appleAuth = async (props: appleAuthType) => {
      return createRequest(
          authApiAxios.post(`/user/appleauth`, {
             id_token: props.authorization.id_token,
             first_name: props.user.name.firstName,
             last_name: props.user.name.lastName
          })
      );
   };
   createPassword = async (props: createPasswordType) => {
      return createRequest(
         authApiAxios.put(`${baseUrl}/${this.user_id}/code/${props.code}`, {
            password: props.password,
            password_confirm: props.password_confirm,
         })
      );
   };

   changePassword = async (data: changePasswordType) => {
      return await createRequest(
         authApiAxios.put(`${baseUrl}/${this.user_id}`, data)
      );
   };
}

// create instance
export const authApi = new AuthApiClass();

// subscribe to store to change id
const changeId = () => {
   let prevId = authApi.user_id;
   let newId = store.getState().authReducer.user_id;

   if (prevId !== newId) {
      authApi.user_id = newId as string;
   }
};
const unsubscribe = store.subscribe(changeId);
